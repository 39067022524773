import {AnnouncementBanner} from '../types/announcement-banner.types'
import {AnnouncementBannerFieldsFragment} from '../../../../__generated__/datocms.types'
import {validateDatoCmsTypes} from './common.serde'

export function parseAnnouncementBanner(a: AnnouncementBannerFieldsFragment): AnnouncementBanner {
  const banner = validateDatoCmsTypes(a)
  const {id, ...rest} = banner

  return {
    id,
    ...rest,
  }
}
