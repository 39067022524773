import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {AnnouncementBanner} from '../types/announcement-banner.types'
import {map} from 'rxjs/operators'
import {TranslocoService} from '@ngneat/transloco'
import {GetAnnouncementBannersByLocationGql} from '../../../../__generated__/datocms.types'
import {getSiteLocale} from '../site-locale'
import {pickProperty} from '../../graphql/graphql-utils'
import {parseAnnouncementBanner} from '../serde/announcement-banner-serde'
import {CMS_GRAPHQL_CLIENT_NAME} from '../content-graphql-split'

@Injectable({
  providedIn: 'root',
})
export class AnnouncementBannerContentService {
  constructor(
    private readonly locale: TranslocoService,
    private readonly getAnnouncementBannerByLocationGql: GetAnnouncementBannersByLocationGql,
  ) {}

  getAnnouncementBannersByLocation(location: string): Observable<AnnouncementBanner | undefined> {
    return this.getAnnouncementBannerByLocationGql
      .fetch({locale: getSiteLocale(this.locale), location}, {context: {clientName: CMS_GRAPHQL_CLIENT_NAME}})
      .pipe(
        map(res => {
          if (res.data.announcementBannerV2Model) {
            return parseAnnouncementBanner(pickProperty(res.data, 'announcementBannerV2Model'))
          } else {
            return undefined
          }
        }),
      )
  }
}
